<!-- Outer container -->
<div class="d-flex flex-column flex-nowrap vw-100 vh-100">
  <!-- Upper navbar (sticky) -->
  <header class="d-block flex-grow-0">
    <!-- Actual navigation bar (gives functionality) -->
    <nav class="navbar navbar-expand-sm navbar-dark bg-primary bg-gradient">
      <div class="container-fluid">
        <!-- Small logo -->
        <a class="navbar-brand" href="#">
          <img class="logo-sm" src="../assets/logo-white-static.svg">
          <span>&nbsp;DOME Registry</span>
        </a>
        <!-- Collapse button -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbar-supported-content" aria-controls="navbar-supported-content" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Collapsable content -->
        <div class="navbar-collapse collapse " id="navbar-supported-content">
          <!-- navbar in center -->
          <ul class="navbar-nav ms-auto">
            <li class="nav-item  ">
              <a class="nav-link " [routerLinkActive]="['active']" [routerLink]="'/intro'"><b>Home</b></a>

            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="'/stats'"><b>Statistics</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="'/search'"><b>Browse</b></a>
            </li>
            <li class="nav-item">
              <!-- <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="'/review'"><b>Submit</b></a> -->
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="'/review'"><b>Submit</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['active']" href="/api/"><b>API</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="'/about'"><b>About</b></a>
            </li>
          </ul>
           
           
  
          <!-- Right side -->
          <ul class="navbar-nav ms-auto me-0">
            <!-- Get user -->
            <ng-container *ngIf="user$ | async as user">
              <!-- Case user is set -->
              <ng-container *ngIf="!!user.auth; else userNotAuthorized">
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle active" href="#" id="navbar-menu" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{user.orcid}}&nbsp;<i class="bi bi-person-circle bi-orcid"></i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbar-menu">
                    <!-- TODO Show user information -->
                    <li>
                      <a class="dropdown-item">
                        <div>{{user.name || 'Unknown user'}}</div>
                        <div class="small"><i class="bi bi-person-circle text-orcid"></i><b>&nbsp;{{user.orcid}}</b>
                        </div>
                        <div class="small"><i class="bi bi-envelope text-orcid"></i>&nbsp;{{user.email || '-'}}</div>
                       
                      </a>
                    </li>
                    <li>
                      <div class="dropdown-item cursor-pointer"><i class="bi bi-person-circle text-orcid"></i>&nbsp;{{user.roles || '-'}}</div>
                    </li>
                    <!-- Show logout button -->
                    <li>
                      <a class="dropdown-item cursor-pointer" (click)="logout()">
                        <i class="bi bi-box-arrow-right"></i>&nbsp;Logout
                      </a>
                    </li>
                   
                  </ul>
                </li>
              </ng-container>
              <!-- Otherwise -->
              <ng-template #userNotAuthorized>
                <!-- Case user is not set -->
                <li class="nav-item">
                  <!-- Define value -->
                  <a class="nav-link" [href]="login">
                    <span>Sign in&nbsp;<i class="bi bi-person-circle"></i></span>
                  </a>
                </li>
              </ng-template>
            </ng-container>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <!-- Page content (scrollable) -->
  <main class="d-block flex-grow-1 overflow-hidden">
    <!-- After user loaded, show page -->
    <ng-container *ngIf="user$ | async; else showLoadingSpinner">
      <!-- Show current page -->
      <router-outlet></router-outlet>
    </ng-container>
    <!-- Before user loaded, show spinners -->
    <ng-template #showLoadingSpinner>
      <!-- Otherwise -->
      <div class="d-flex w-100 h-100 flex-row flex-wrap align-items-center justify-content-center">
        <!-- Show loading animation (three times) -->
        <div class="spinner-grow text-primary delay-1 slow me-3" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-primary delay-2 slow me-3" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-primary delay-3 slow me-0" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </ng-template>
  </main>
  <!-- Bottom footer (sticky) -->
  <footer class="d-block flex-grow-0 ">
    <div class="d-flex flex-row align-items-center bg-gradient py-2">
      <span class="d-inline ms-3 me-auto">
        <span class="d-none d-sm-inline">Supported by&nbsp;</span>
        <a href="https://elixir-europe.org/">
          <img class="logo-sm cursor-pointer" src="assets/elixir.svg">
        </a>
      </span>
      <span class="d-inline me-auto">

        <span class="d-none d-sm-inline"> <a class="text-white"  href="#">Version 2.1 </a> </span>

      </span>
      <span class="d-inline me-3">
        <span class="d-none d-sm-inline">Developed and maintained by&nbsp;</span>
        <a class="text-white" href="https://github.com/BioComputingUP">BioCompUP <i class="bi bi-github"></i></a>
      </span>
    </div>
  </footer>
</div>