<card-template>
  <!-- Define card title -->
  <h4 title>Index</h4>
  <!-- Define card body -->
  <div body>
    <!-- Define navbar scaffold -->
    <nav class="nav nav-pills flex-column">
      <ng-content select="[link]"></ng-content>
    </nav>
  </div>
</card-template>
