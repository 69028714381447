<!-- Main card -->
<div class="card bg-white shadow-sm">
  <!-- Header -->
  <div class="card-header bg-primary text-white">
    <ng-content select="[title]"></ng-content>
  </div>
  <!-- Body -->
  <div class="card-body">
    <ng-content select="[body]"></ng-content>
  </div>
  <!-- Footer -->
</div>
