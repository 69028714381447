<div class="d-flex flex-column h-100 w-100 overflow-y-auto">
  <!-- Retrieve review, if any -->
  <ng-container *ngIf="review$ | async as review">
    <!-- Case user is authorized, show edit form -->
    <ng-container *ngIf="user.auth || review.uuid ; else signInFirst">
      <!-- Inner scaffold   -->
      <div class="container-fluid flex-grow-1 bg-light h-100 overflow-scroll" [root]="element" [target]="'#links'"
        scroll-spy>
        <div class="row position-relative overflow-auto">

        </div>
      </div>
    </ng-container>
  </ng-container>



  <ng-template #signInFirst>
    <!-- Inner scaffold -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <card-template class="d-block shadow-sm mt-3">
            <!-- Set card title -->
            <h4 class="text-center" title>Submit</h4>
            <!-- Set card body -->
            <div body>
              <p class="d-block mb-3">
               You cannot acces to this page you have to own the admin previleges to have acces 
              </p>
              <p class="d-block mb-3">
                It is essential to underscore that access to this functionality is restricted solely to authorized
                users.
                Hence, we kindly request that you authenticate through ORCID if you intend to engage with this service
                in an academic capacity
              </p>
              <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">
                <a class="btn btn-orcid" [href]="login" role="button">
                  Sign in&nbsp;<i class="bi bi-person-circle"></i>
                </a>
              </div>
            </div>
          </card-template>
        </div>
      </div>
    </div>
  </ng-template>