<!--&lt;!&ndash; Main card &ndash;&gt;-->
<!--<div class="card bg-white shadow-sm">-->
<!--  &lt;!&ndash; Header &ndash;&gt;-->
<!--  <div class="card-header bg-primary text-white">-->
<!--    <ng-content select="[title]"></ng-content>-->
<!--  </div>-->
<!--  &lt;!&ndash; Body &ndash;&gt;-->
<!--  <div class="card-body">-->
<!--    <ng-content select="[body]"></ng-content>-->
<!--  </div>-->
<!--  &lt;!&ndash; Footer &ndash;&gt;-->
<!--</div>-->
<card-template>
  <!-- Define card title -->
  <h4 title>
    <!-- Pass forward section title -->
    <ng-content select="[title]"></ng-content>
  </h4>
  <!-- Define card body -->
  <div body>
    <!-- Allow to define custom form -->
    <ng-content select="[fields]"></ng-content>
  </div>
</card-template>
