<div id="outer-div" class="container-fluid  bg-gradient w-100 h-100  ">
    <div id="inner-div" class="  overflow-auto w-100 h-100">

        <div class="container text-white">
            <div class="row m-3">
                    
                    
                        
                <card-template class="d-block shadow-sm mt-3">
                    <!-- Set card title -->
                    <h1 class="text-center" title>Tutorial </h1>
                    <!-- Set card body -->
                    <div body>
                       
                            <ul class=" text-align-left text-black">

                                <li class="mb-3"> 
                                    <b>
                                        The DOME registry provides users with the opportunity to contribute annotations using the DATA Stewardship Wizards tool. 
                                        These submitted annotations will undergo a manual review process,
                                        ultimately leading to publication.
                                    </b>
                                </li>
                                <li class="mb-3">
                                    <b>
                                        Published annotations will be accessible to the public but will remain immutable.
                                        Conversely, unpublished annotations will be subject to user-driven viewing, editing, and deletion.
                                    </b>
                                        
                                    </li>
                                    <li class="mb-3">
                                        <b>
                                            It is essential to emphasize that access to this functionality is restricted solely to users 
                                            authenticated in DOME Data Stewardship Wizard. <br>
                                            Therefore, we kindly request that you authenticate through DOME Data Stewardship Wizard if you intend to engage with this service in an academic capacity. See the video tutorial 
                                        </b>
                                            
                                     </li>
                                     

                            </ul>
                            <div class="d-flex flex-row flex-wrap justify-content-center align-items-center mb-3">
                                <a type="button" class="btn btn-primary" href="https://dome.dsw.elixir-europe.org/"
                                target="_blank">GO TO DOME  WIZARD <i class="bi bi-arrow-right"></i></a>
                            </div>
                        <div class="embed-responsive embed-responsive-16by9">
                              <iframe width="1200" height="630"
                                        src="https://www.youtube.com/embed/QNPzQrIeTkk?si=hPzaHOIiNtfo1uRz"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>
                                    </iframe>
                                    </div>
                                   
                                </div>

                                   
                </card-template>   <!-- <img src="../../assets/dsw-logo-transformed.png"  class=image-fluid alt="DSW logo"> -->

                         
                            
                    


        </div>

        </div>
    </div>
</div>